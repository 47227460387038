import { render, staticRenderFns } from "./ReallocateLoZone.vue?vue&type=template&id=201a8627&"
import script from "./ReallocateLoZone.vue?vue&type=script&lang=js&"
export * from "./ReallocateLoZone.vue?vue&type=script&lang=js&"
import style0 from "./ReallocateLoZone.vue?vue&type=style&index=0&id=201a8627&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports